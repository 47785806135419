.mywork-main {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
}

.mywork-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}

.mywork-content:hover {
    /* opacity: 0.7; */
    box-shadow: rgba(0, 0, 0, 0.8) 0px 19px 43px;
    transform: translate3d(0px, -2px, 0px);
}

.mywork-content-image {
    height: auto;
    width: 100%;
    object-fit: cover;
    z-index: 0;
    border-radius: 10px;
}

.mywork-content-text {
    position: absolute;
}

.mywork-content-title {
    font-weight: bold;
    color: white;
    font-size: 2vw;
    margin-left: 20px;
    margin-bottom: 10px;
    z-index: 1;
    margin-top: 20px;
}

.mywork-content-subtitle {
    color: grey;
    margin-left: 20px;
    width: 40%;
    z-index: 1;
    font-size: 1vw;
}