.home-main {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: left;
    align-items: flex-start
}

.home-name {
    font-weight: bold;
    text-align: left;
    display: flex;
    justify-content: left;
    font-size: 25px;
}

.home-subname {
    text-align: left;
    margin-top: 5px;
    color: grey;
}

.home-intro {
    margin-top: 20px;
    font-size: 15px;
}