.App {
  width: 90%;
  height: 100%;
  margin: auto;
  padding-top: 20px;
}

.App-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.App-logo {
  display: flex;
  align-self: flex-start;
  height: 200px;
  margin-right: 30px;
}

.fifa-logo {
  width: auto;
  height: 200px;
}

.App-menu-child {
  display: flex;
  flex-direction: row;
}

.App-menu {
  display: flex;
  flex-direction: column;
}

.App-menu-section {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.App-menu-section-title {
  font-weight: bold;
  color: #990033;
  margin-bottom: 5px;
}

.App-menu-favorites-text {
  margin-top: 0;
  color: grey;
}

.App-menu-button {
  height: 30px;
  width: 150px;
  margin-top: 15px;
  border: 1px solid white;
  background-color: #990033;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin-left: 20px;
}

.logo {
  width: auto;
  height: 200px;
}

.App-main {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 25px;
  justify-content: space-between;
  margin-bottom: 50px;
}

.MatchCard {
  width: 300px;
  height: 400px;
  background-color: #990033;
  border-radius: 10px;
  text-align: center;
}

.MatchTeams {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 0;
  margin-bottom: -5px;
}

.MatchCardTeamName {
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.MatchCardAttribute {
  color: black;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 5px;
}

.MatchCardDate {
  color: white;
  margin-top: 0
}

.MatchCardLocation {
  color: white;
  margin-top: 0;
}

.MatchCardGroup {
  color: white;
}

.MatchCardNumber {
  color: white;
}

.MatchCardRating {
  display: flex;
  justify-content: center;
}

.MatchCardInfo {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.flag {
  height: 40px;
  width: auto;
  margin-bottom: -10px;
  margin-top: 10px;
}

.MatchCardButton {
  margin-top: 25px;
  width: 80%;
  border-radius: 20px;
  height: 30px;
  border: 1px solid white;
  background-color: #990033;
  color: white;
  font-weight: bold;
  cursor: pointer;
}