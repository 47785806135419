.NavBar {
    width: 80%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.logo {
    height: 80px;
}

.NavBarLinks {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.NavBarLink {
    font-weight: bold;
    cursor: pointer;
    color: black;
}