.about-main {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.about-picture {
    width: auto;
    height: 200px;
    border-radius: 50%;
    border: 1px solid black;
}

.about-info {
    margin-top: 20px;
    width: 70%;
}

.about-icons {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    column-gap: 20px;
}